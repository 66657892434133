import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled(motion.section)`
  background-color: ${(props) => props.theme.colors.color2};
  width: 100vw;
  height: 450px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.sizes.mobile} {
    height: 100%;
  }

  img {
    width: 50vw;
    height: 100%;
    object-fit: cover;

    ${(props) => props.theme.sizes.mobile} {
      width: 80vw;
      position: relative;
      right: 5vw;
      bottom: 65px;
    }
  }
`;

const Column = styled(motion.div)`
  /* border: 2px solid orange; */
  width: 50vw;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  ${(props) => props.theme.sizes.mobile} {
    width: 100vw;
    height: 350px;
  }

  h2 {
    /* border: 2px solid ${(props) => props.theme.colors.cream}; */
    width: 90%;
    padding: 25px;
    text-align: right;
    color: ${(props) => props.theme.colors.white};

    ${(props) => props.theme.sizes.mobile} {
      /* border: 2px solid orange; */
      font-size: 30px;
      position: relative;
      bottom: 50px;
      z-index: 2;

      width: 90%;
      text-align: center;
    }
  }

  h4 {
    color: ${(props) => props.theme.colors.white};
    font-size: 0.7em;
  }

  span {
    color: ${(props) => props.theme.colors.cream};
    font-size: 0.6em;
  }
`;

const Author = styled(motion.div)`
  /* background-color: yellow; */
  display: flex;
  margin: 25px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
`;

const Quotation = (props) => {
  return (
    <Container>
      <Column>
        {/* Author here needed for spacing */}
        <Author></Author>
        <h2>{props.quote}</h2>
        <Author>
          <h4>{props.speaker}</h4>
          <span>{props.role}</span>
        </Author>
      </Column>
      <img src={props.imgsrc}></img>
    </Container>
  );
};

export default Quotation;
