import React, { useRef, useState } from "react";
import GlobalStyle from "styles/GlobalStyle";
import Theme from "styles/Theme";
import styled, { ThemeProvider } from "styled-components";
import Footer from "./Footer";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: calc(${(props) => props.height}px - 0px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const PageLayout = ({ children }) => {
  const footerRef = useRef(null);
  const [minHeight, setMinHeight] = useState(0);

  //this is calculating the page height so we can show the footer at the bottom.
  React.useLayoutEffect(() => {
    let pageHeight = window.innerHeight;
    let footerHeight = footerRef.current.clientHeight;
    let calcHeight = pageHeight - footerHeight;

    // console.log("Before inner height of window: " + pageHeight);
    // console.log("Footer height: " + footerHeight);
    // console.log("Value of calculated height: " + calcHeight);

    if (minHeight !== calcHeight) {
      setMinHeight(calcHeight);
    }
  }, [minHeight]);

  return (
    <>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Wrapper height={minHeight}>{children}</Wrapper>
        <div ref={footerRef}>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};

export default PageLayout;
