import React from "react";
import styled from "styled-components";
import blogTo from "../assets/images/PE_BlogTo.png";

const CardContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0px;
  margin: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  display: inline-block;
  max-width: 31%;

  &:hover {
    transform: scale(1.05);
  }

  ${(props) => props.theme.sizes.mobile} {
    max-width: 80%;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
`;

const ClickableCard = () => {
  return (
    <CardContainer>
      <a
        href="https://www.blogto.com/fashion/project-essence-toronto/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CardImage src={blogTo} alt="Card Image" />
      </a>
    </CardContainer>
  );
};

export default ClickableCard;
