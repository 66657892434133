import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import vip1 from "../../assets/images/vip1.jpg";
import vip2 from "../../assets/images/vip2.jpg";
import vip3 from "../../assets/images/vip3.jpg";
import vip4 from "../../assets/images/vip4.jpg";

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.color1};
`;

const Container = styled(motion.section)`
  /* border: 2px solid yellow; */
  max-width: 1400px;
  width: 100%;
  padding: 40px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.sizes.mobile} {
    height: 100%;
    flex-direction: column-reverse;
  }
`;

const H2Container = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.color1};
  width: 100vw;
  margin-top: 40px;
  text-align: center;

  ${(props) => props.theme.sizes.mobile} {
  }

  ${(props) => props.theme.sizes.tablet} {
  }
`;

const H2Text = styled(motion.h3)`
  /* border: 1px solid black; */
  color: ${(props) => props.theme.colors.color3};
  width: 80%;
  max-width: 1400px;
  margin: auto;
  font-size: 1.4em;

  /* padding-bottom: 40px;
  font-size: 1.3em; */

  ${(props) => props.theme.sizes.mobile} {
    width: 90%;
  }

  ${(props) => props.theme.sizes.tablet} {
    width: 90%;
  }
  ${(props) => props.theme.sizes.laptop} {
    width: 85%;
  }
`;

const Row = styled.div`
  /* background-color: orange; */
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 20%;

    ${(props) => props.theme.sizes.mobile} {
      width: 50%;
      padding: 15px;
    }

    ${(props) => props.theme.sizes.tablet} {
      width: 50%;
      padding: 25px;
    }
  }
`;

const VipStations = (props) => {
  return (
    <Background>
      <Container>
        <H2Container>
          <H2Text>
            project essence will always be there for you when you need to
            disconnect from the world around you. while you are in our caring
            hands, let the colour of your future manicure be your biggest worry.
          </H2Text>
        </H2Container>
      </Container>
      <Container>
        <Row>
          <img src={vip1}></img>
          <img src={vip4}></img>
          <img src={vip3}></img>
          <img src={vip2}></img>
        </Row>
      </Container>
    </Background>
  );
};

export default VipStations;
