import styled from "styled-components";
import { motion } from "framer-motion";
import React, { useState } from "react";
import ScrollChevron from "./ScrollChevron";
import videoH01 from "../../assets/videos/videoH03.mp4";
import BookButton from "../Button";
import imageURL from "../../assets/images/project-essence-icon-inc.svg";

const Container = styled(motion.div)`
  width: 100vw;
  height: ${(props) => props.height}px;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
  }
`;

const Title = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.color4 + `AA`};
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  ${(props) => props.theme.sizes.mobile} {
    padding: 15px;
  }

  img {
    width: 400px;

    ${(props) => props.theme.sizes.mobile} {
      /* background-color: orange; */
      width: 80vw;
    }
  }
`;

const ButtonContainer = styled(motion.div)`
  width: 100%;
  height: 50px;
  font-size: 18px;
`;

const Video = ({ delayAnimation }) => {
  const [pageHeight, setPageHeight] = useState(0);

  React.useLayoutEffect(() => {
    // this needs to be done because with SSR we don't have access to window earlier
    if (pageHeight !== window.innerHeight) {
      setPageHeight(window.innerHeight);
    }
  }, [pageHeight]);

  return (
    <>
      <Container height={pageHeight}>
        <video playsInline autoPlay loop muted>
          <source
            src={videoH01}
            type="video/mp4"
            poster="../../assets/images/quote4.jpg"
          />
          Your browser does not support the video tag.
        </video>
        <Title>
          <img src={imageURL} alt="Our Full Logo" />
          <ButtonContainer>
            <BookButton
              linkTo={"/booking"}
              description={"book with us"}
              text={"book"}
            />
          </ButtonContainer>
        </Title>
        <ScrollChevron />
      </Container>
    </>
  );
};

export default Video;
