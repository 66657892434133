import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import nail1 from "../assets/images/nail1.jpg";
import nail2 from "../assets/images/nail2.jpg";
import nail3 from "../assets/images/nail3.jpg";
import nail4 from "../assets/images/nail4.jpg";
import nail5 from "../assets/images/nail5.jpg";
import ClickableCard from "../components/ClickableCard";

const Container = styled(motion.section)`
  /* border: 2px solid yellow; */
  max-width: 1400px;
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.sizes.mobile} {
    height: 100%;
    flex-direction: column-reverse;
  }
`;

const ImageContainer = styled(motion.div)`
  /* border: 2px solid black; */
  width: 30%;
  object-fit: cover;

  ${(props) => props.theme.sizes.mobile} {
    padding: 15px;
    width: 90%;
  }

  img {
    max-width: 400px;
  }
`;

const Text = styled(motion.div)`
  /* border: 2px solid orange; */
  width: 30%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: left;

  ${(props) => props.theme.sizes.mobile} {
    width: 90%;
  }

  ${(props) => props.theme.sizes.tablet} {
    width: 85%;
  }
`;

const PText = styled(motion.p)`
  padding-bottom: 40px;
  font-size: 1.3em;

  ${(props) => props.theme.sizes.mobile} {
    font-size: 1em;
    padding-bottom: 25px;
  }

  ${(props) => props.theme.sizes.tablet} {
    font-size: 1.1em;
    padding-bottom: 30px;
  }
  ${(props) => props.theme.sizes.laptop} {
    font-size: 1.2em;
  }
`;

const SecondContainer = styled(motion.section)`
  /* border: 2px solid yellow; */
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  /* background-color: orange; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  img {
    width: 20%;

    ${(props) => props.theme.sizes.mobile} {
      width: 50%;
      padding: 15px;
    }

    ${(props) => props.theme.sizes.tablet} {
      width: 50%;
      padding: 25px;
    }
  }
`;

const Block = (props) => {
  return (
    <>
      <Container>
        <ClickableCard />
        <Text>
          <PText>
            project essence invites you to embrace your personal journey of
            finding harmony, inner allure, and delightful manicures at our
            beauty retreat, located in the middle of the fourth largest city in
            North America – Toronto.
          </PText>
          <PText>
            aside from beauty and health reasons, getting your nails expertly
            done in a sanctuary-like atmosphere will provoke the calmness that
            we habitually miss in our regular lives.
          </PText>
        </Text>
        <ImageContainer>
          <img src={nail5} width="100%" alt="Client getting pedicure"></img>
        </ImageContainer>
      </Container>
      <SecondContainer>
        <PText>
          so whether you always take care of your hands and feet or you are new
          to this self-care practice, let us be your dedicated guides into the
          world of professional nail care.
        </PText>
        <Row>
          <img src={nail1} alt="olive green manicure"></img>
          <img src={nail2} alt="tan pedicure"></img>
          <img src={nail4} alt="red pedicure"></img>
          <img src={nail3} alt="red manicure"></img>
        </Row>
      </SecondContainer>
    </>
  );
};

export default Block;
