import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import toronto from "../../assets/images/toronto.jpg";

const Background = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  img {
    object-fit: cover;
    width: 100%;
    height: 400px;
    filter: grayscale(70%);
  }
`;

const Container = styled(motion.section)`
  /* border: 2px solid yellow; */
  max-width: 1400px;
  width: 80%;
  padding: 40px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.3em;
    text-align: left;
    color: ${(props) => props.theme.colors.color4};

    ${(props) => props.theme.sizes.mobile} {
      font-size: 1em;
    }

    ${(props) => props.theme.sizes.tablet} {
      font-size: 1.1em;
    }
    ${(props) => props.theme.sizes.laptop} {
      font-size: 1.2em;
    }
  }
`;

const Ossington = (props) => {
  return (
    <>
      <Background>
        <img src={toronto}></img>
        <Container>
          <p>
            we are proud to be based on the Ossington strip, one of world's
            coolest streets according to Time Out magazine. this famous stretch
            hosts Toronto's best shops, eateries, and bars. needless to say, we
            encourage you to visit our neighbors for a quick bite, shopping
            spree, or a night out - after getting the most flawless mani at
            project essence, of course. by the way, did you know that Ossington
            was first constructed as a military road in the 1800s? who knew!
          </p>
        </Container>
      </Background>
    </>
  );
};

export default Ossington;
