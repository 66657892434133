import styled from "styled-components";
import React, { useState } from "react";
import BookButton from "../Button";

const Title = styled.h2`
  padding: 0 15px;
  padding-bottom: 30px;
  color: ${(props) => props.theme.colors.color4};
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 200px;
  height: 80px;
  text-decoration: none;
`;

const GiftCard = () => {
  return (
    <>
      <Title>
        unlock best <i>self-care</i> gift of flawless nails
      </Title>
      <ButtonContainer>
        <BookButton
          linkTo={"https://squareup.com/gift/MLCE8W5CQFJZF/order"}
          description={"get project essence gift card"}
          text={"buy gift cards"}
          newTab={true}
        />
      </ButtonContainer>
    </>
  );
};

export default GiftCard;
