import styled from "styled-components";
import { motion } from "framer-motion";
import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

const Container = styled(motion.div)`
  /* background-color: black; */
  padding-bottom: 75px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    /* background-color: orange; */
    position: absolute;
    width: 25px;
    height: 5px;
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
    animation: move 3s ease-out infinite;
  }

  div:first-child {
    animation: move 3s ease-out 1s infinite;
  }

  div:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }

  div:before,
  div:after {
    content: " ";
    position: absolute;
    top: 0px;
    height: 100%;
    width: 50%;
    background: ${(props) => props.theme.colors.color6};
  }

  div:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }

  div:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }

  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }

  @keyframes pulse {
    to {
      opacity: 1;
    }
  }
`;

const ScrollChevron = () => {
  return (
    <>
      <Container onClick={() => scrollTo("#navbarlinks")}>
        <div></div>
        <div></div>
        <div></div>
      </Container>
    </>
  );
};

export default ScrollChevron;
