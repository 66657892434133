import * as React from "react";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Video from "../components/index/Video";
import Quotation from "../components/Quotation";
import Block from "../components/Block";
import pic2 from "../assets/images/quote2.jpg";
import VipStations from "../components/index/VipStations";
import quote3 from "../assets/images/quote3.jpg";
import quote4 from "../assets/images/quote4.jpg";
import Ossington from "../components/index/Ossington";
import GiftCard from "../components/index/GiftCard";

const Wrapper = styled.div`
  /* background-color: ${(props) => props.theme.colors.color3 + `33`}; */
  /* border: 2px solid black; */
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    max-width: 900px;
  }
`;

const SpaceHolder = styled.div`
  height: ${(props) => props.space}px;
`;

const IndexPage = () => {
  return (
    <>
      <PageLayout>
        <Video />
        <Navbar pageAt="index" />
        <SpaceHolder space={80} />
        <GiftCard />
        <SpaceHolder space={80} />
        <Wrapper>
          <Quotation
            quote="to love oneself is the beginning of a lifelong romance"
            speaker="Oscar Wilde"
            role="poet"
            imgsrc={quote3}
          />
          <SpaceHolder space={30} />
          <Block
            imgsrc={pic2}
            quote="project essence invites you to embrace your personal jorney of
              finding harmony, inner allure and delightful manicure at our
              beauty retreat, located in the middle of the 4th largest and
              busiest city on North America - Toronto."
          ></Block>
          <SpaceHolder space={100} />
          <Quotation
            quote="almost everything will work again if you unplug it for a few minutes, including you"
            speaker="Anne Lamott"
            role="writer"
            imgsrc={pic2}
          />
          <SpaceHolder space={100} />
          <VipStations></VipStations>
          <SpaceHolder space={100} />
          <Quotation
            quote="while you are in our caring hands, let the colour of your future manicure be your biggest worry"
            speaker=""
            role=""
            imgsrc={quote4}
          />
          <SpaceHolder space={100} />
          <Ossington></Ossington>
          <SpaceHolder space={80} />
        </Wrapper>
      </PageLayout>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Project Essence</title>;
